
import gsap from 'gsap'
const promise = []
export default {
  name: 'Intro',
  transition: {
    beforeLeave() {
      gsap.fromTo('.IntroPage-Portfolio', {
        rotate: '0',
        transformOrigin: 'center',
        transformPerspective: 2000,
      },{
        duration:0.5,
        stagger: 0,
        rotate: '90deg',
      })
      gsap.fromTo('.IntroPage-Portfolio .text', {
        with: 'auto',
        autoAlpha:1,
        transformOrigin: 'center',
        transformPerspective: 2000,
      },{
        duration:0.2,
        autoAlpha:0,
        background: 'none',
        stagger: 0.2,
        width: 0,
      })
      gsap.fromTo('.IntroPage-Portfolio', {
        autoAlpha: 1
      },{
        duration:0.3,
        delay:0.4,
        autoAlpha: 0,
        background:'none'
      })
      gsap.fromTo('.IntroPage-Portfolio .h-last', {
        translateX: 0,
        autoAlpha:1,
      },{
        duration:0.3,
        delay: 0.5,
        translateX: '100vh',
        autoAlpha:0,
        stagger: 0.1,
      })
      gsap.fromTo('.IntroPage-Portfolio .h-right', {
        translateX: 0,
        autoAlpha:1,
      },{
        duration:0.3,
        delay: 0.7,
        translateX: '100vh',
        autoAlpha:0,
        stagger: 0.1,
      })
      gsap.fromTo('.IntroPage-Portfolio .h-left', {
        translateX: 0,
        autoAlpha:1,
      },{
        duration:0.3,
        delay: 0.9,
        translateX: '100vh',
        autoAlpha:0,
        stagger: 0.1,
      })
      promise.push(new Promise(resolve => {
        gsap.fromTo(['.IntroPage h3','.IntroPage h2'], {
          autoAlpha: 1,
          ease: "power3.inOut",
          scale: 1,
          clearProps: 'all'
        },{
          duration: 1,
          stagger: 0.2,
          autoAlpha: 0,
          scale: 0.8,
          transformOrigin: 'top center -140',
          transformPerspective: 2000,
          onComplete: resolve
        })
      }))
      promise.push(new Promise(resolve => {
        gsap.fromTo('#canvas', { opacity: 1, duration: 0.7}, { opacity: 0, stagger: 0.3,onComplete: resolve})
      }))
    },
    async leave(el, done) {
      await Promise.all(promise)
      done()
    },
  },
  data(){
    return {
      dotsLoaded: false,
      effect: null,
    }
  },
  head () {
    return {
      title: 'CHZ.DEV - Chingiz Mammadov - Front End Engineer',
      script: [
        {
          hid: 'tree',
          src: '/js/tree.min.js',
          defer: true,
          callback: () => { this.dotsLoaded = true }
        }
      ]
    }
  },
  watch:{
    dotsLoaded(val){
      if(val) {
        // eslint-disable-next-line no-undef
        VANTA.DOTS({
          el: '#canvas',
          mouseControls: true,
          touchControls: true,
          gyroControls: true,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          color: 0x01a093,
          color2: 0xf2956b,
          backgroundColor: 0xe8e8e8,
          size: 2.10,
          spacing: 20.00
        })
        gsap.to('#canvas', { opacity: 1, duration: 1})
      }
    }
  },
  mounted() {
    gsap.fromTo(['.IntroPage h2','.IntroPage h3','.IntroPage-Portfolio'], {
      autoAlpha: 0,
      scale: 0.8,
      transformOrigin: 'top center -140',
      transformPerspective: 2000,
    },{
      duration:1,
      stagger: 0.1,
      autoAlpha: 1,
      scale: 1,
      clearProps: 'all'
    })
  },
  methods: {
    goPortfolio(){
      gsap.fromTo('.IntroPage-Portfolio', {
        rotate: '0',
        transformOrigin: 'center',
        transformPerspective: 2000,
      },{
        duration:0.4,
        stagger: 0,
        rotate: '90deg',
      })
      gsap.fromTo('.IntroPage-Portfolio .text', {
        with: 'auto',
        autoAlpha:1,
        transformOrigin: 'center',
        transformPerspective: 2000,
      },{
        duration:0.2,
        autoAlpha:0,
        background: 'none',
        stagger: 0.5,
        width: 0,
      })
      gsap.to('.IntroPage-Portfolio', {
        duration:0.3,
        delay:0.4,
        background:'none'
      })
      gsap.fromTo('.IntroPage-Portfolio .h-last', {
        translateX: 0,
        autoAlpha:1,
      },{
        duration:0.3,
        delay: 0.5,
        translateX: '100vh',
        autoAlpha:0,
        stagger: 0.1,
      })
      gsap.fromTo('.IntroPage-Portfolio .h-right', {
        translateX: 0,
        autoAlpha:1,
      },{
        duration:0.3,
        delay: 0.7,
        translateX: '100vh',
        autoAlpha:0,
        stagger: 0.1,
      })
      gsap.fromTo('.IntroPage-Portfolio .h-left', {
        translateX: 0,
        autoAlpha:1,
      },{
        duration:0.3,
        delay: 0.9,
        translateX: '100vh',
        autoAlpha:0,
        stagger: 0.1,
      })
    }
  }
}
